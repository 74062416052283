import React, { useEffect, useState, useContext } from "react";
import AppLayout from "../layouts/AppLayout";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import "../styles/settings.css";
import "@dsalvagni/react-profile-picture/dist/ProfilePicture.css";
import { AppContext } from "../contexts/AppContext";
import { BsKey } from "react-icons/bs";

export default function ChangePassword() {
  const [oldpassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isFormChanged, setIsFormChanged] = useState(false);

  const { updatepassword, isLoading } = useContext(AppContext);

  useEffect(() => {
    if (oldpassword !== "" && newpassword !== "" && confirmpassword !== "") {
      setIsFormChanged(true);
    } else {
      setIsFormChanged(false);
    }
  }, [oldpassword, newpassword, confirmpassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading === false) {
      try {
        if (oldpassword !== "" && newpassword !== "" && confirmpassword !== "") {
          if (oldpassword.length < 6 || newpassword.length < 6 || confirmpassword.length < 6) {
            setError("Password must have at least 6 characters");
            return;
          } else if (newpassword !== confirmpassword) {
            setError("Passwords don't match");
            return;
          } else {
            setError("");
            const response = await updatepassword(oldpassword, newpassword, confirmpassword);
            if (response === "success") {
              setOldPassword("");
              setNewPassword("");
              setConfirmPassword("");
            }
          }
        } else {
          setError("All fields are required");
          return;
        }
      } catch (error) {
        setError("Update failed: " + error);
      }
    }
  };

  return (
    <AppLayout pageName={"Changez le mot de passe"}>
      <section>
        <main className="settings">
          <form onSubmit={handleSubmit} className="space-y-5 lg:w-1/2 md:w-full">
            <div className="form-group !mt-0">
              <label htmlFor="oldpassword">Mot de passe actuel</label>
              <div className="input-icon">
                <BsKey />
              </div>
              <input type="password" id="oldpassword" placeholder="******" value={oldpassword} className="form-control" onChange={(e) => setOldPassword(e.target.value)} required />
            </div>
            <div className="flex items-center p-4 text-neutral-500 border-t-4 border-neutral-200 bg-neutral-100" role="alert">
              <svg className="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <div className="ms-3 text-sm font-medium">Le nouveau mot de passe doit comporter au moins 6 caractères</div>
            </div>
            <div className="form-group">
              <label htmlFor="newpassword">Nouveau mot de passe</label>
              <div className="input-icon">
                <BsKey />
              </div>
              <input type="password" id="newpassword" placeholder="******" value={newpassword} className="form-control" onChange={(e) => setNewPassword(e.target.value)} required />
            </div>
            <div className="form-group">
              <label htmlFor="confirmpassword">Confirmer le nouveau mot de passe</label>
              <div className="input-icon">
                <BsKey />
              </div>
              <input type="password" id="confirmpassword" placeholder="******" value={confirmpassword} className="form-control" onChange={(e) => setConfirmPassword(e.target.value)} required />
            </div>
            {error !== "" ? <div className="text-center text-red-500 font-semibold text-md">{error}</div> : <div className="py-3"></div>}
            <div>
              <button type="submit" className="btn-submit w-full text-center justify-center !py-4" disabled={isLoading || !isFormChanged}>
                {isLoading === false ? "Sauvegarder les modifications" : <Bounce className="py-[7px]" animating={isLoading} />}
              </button>
            </div>
          </form>
        </main>
      </section>
    </AppLayout>
  );
}
