import React, { useState, useContext, useEffect } from "react";
import AppLayout from "../layouts/AppLayout";
import { Link } from "react-router-dom";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";
import "../styles/settings.css";
import { HiArrowNarrowRight } from "react-icons/hi";
import { AppContext } from "../contexts/AppContext";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { AiOutlineUser } from "react-icons/ai";
import "react-phone-number-input/style.css";

export default function MyProfile() {
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [name, setName] = useState(userToken.name);
  const [email, setEmail] = useState(userToken.email);
  const type = userToken.type;
  const [error, setError] = useState("");
  const [isFormChanged, setIsFormChanged] = useState(false);

  const { updateprofile, isLoading } = useContext(AppContext);

  useEffect(() => {
    if(userToken.name.type === 'Admin'){
      if (name !== userToken.name || email !== userToken.email) {
        setIsFormChanged(true);
      } else {
        setIsFormChanged(false);
      }
    }
    else{
      if (name !== userToken.name) {
        setIsFormChanged(true);
      } else {
        setIsFormChanged(false);
      }
    }
  }, [name, email, userToken.name, userToken.email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading === false) {
      try {
        if (name !== "" && email !== "") {
          setError("");
          await updateprofile(name, email);
        } else {
          setError("All fields are required");
          return;
        }
      } catch (error) {
        setError("Update failed: " + error);
      }
    }
  };

  return (
    <AppLayout pageName={"Mon Compte"}>
      <section>
        <main className="settings">
          <form onSubmit={handleSubmit} className="space-y-5 py-2 w-full lg:w-1/2">
            <div className="form-group !mt-0 name">
              <label htmlFor="name">Nom</label>
              <div className="input-icon">
                <AiOutlineUser />
              </div>
              <input id="name" name="name" required type="text" className="form-control" placeholder="John" value={name} onChange={(e) => setName(e.target.value)} maxLength={50} />
            </div>
            <div className={`form-group email ${type !== "Admin" && "opacity-50"} `}>
              <div className="flex justify-between">
                <label htmlFor="email">Adresse e-mail</label>
                {type !== "Admin" && <span className="text-red-600 font-medium text-sm">Impossible de modifier</span>}
              </div>
              <div className="input-icon">
                <HiOutlineEnvelope />
              </div>
              <input id="email" name="email" required type="email" className="form-control" placeholder="hello@protectartists.com" value={email} onChange={(e) => setEmail(e.target.value)} maxLength={255} readOnly={type !== "Admin" ? true : false} />
            </div>

            {error !== "" ? <div className="text-center text-red-500 font-semibold text-md">{error}</div> : <div className="py-3"></div>}
            <div className="block lg:flex flex-row space-x-0 lg:space-x-3 space-y-5 lg:space-y-0">
              <div className="lg:w-1/2 w-full">
                <button type="submit" className="btn-submit w-full text-center justify-center !py-4" disabled={isLoading || !isFormChanged}>
                  {isLoading === false ? "Sauvegarder les modifications" : <Bounce className="py-[7px]" animating={isLoading} />}
                </button>
              </div>
              <div className="lg:w-1/2 w-full">
                <Link to={"/change-password"} className="btn-submit-alt text-center justify-center !py-4">
                  Mise à jour du mot de passe <HiArrowNarrowRight className="ml-2" size={16} />
                </Link>
              </div>
            </div>
          </form>
        </main>
      </section>
    </AppLayout>
  );
}
