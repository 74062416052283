import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import BASE_URL from "../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "./AuthContext";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const { userToken, setIsLoggedIn, isLoggedIn, setuserToken } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);

  const verifySession = async () => {
    if (userToken) {
      try {
        const response = await axios.post(`${BASE_URL}/user/verifysession`, {
          userid: userToken.userid,
        });

        if (response.data.message === "success") {
          localStorage.setItem("userToken", JSON.stringify(response.data.userToken[0]));

          const token = JSON.parse(localStorage.getItem("userToken"));
          setuserToken(token);
        } else {
          logout();
        }
      } catch (error) {
        console.error("Verify failed: " + error);
        logout();
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(verifySession, 60000 * 1);
    return () => clearInterval(interval);
  }, [userToken]);

  const getdashboard = async () => {
    try {
      setIsLoading(true);
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/dashboard/stats`, {
        userid: userToken.userid,
        usertype: userToken.type,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getmembers = async (statusfilter) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/members/all`, { statusfilter });

      if (response.data.message === "success") {
        setIsLoading(false);
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getmember = async (userid) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/members/get`, {
        userid,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const reviewmember = async ({ userid, reviewaction }) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/members/review`, {
        userid,
        reviewaction,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        toast.success("Member Account " + reviewaction + " Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const addmember = async ({ name, email, password, confirmpassword }) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/members/add`, {
        name,
        email,
        password,
        confirmpassword,
      });

      if (response.data.message === "success") {
        toast.success("Member Added Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const updatemember = async ({ name, email, status, userId }) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/members/update`, {
        name,
        email,
        status,
        userid: userId,
      });

      if (response.data.message === "success") {
        toast.success("Member Updated Successfully!");
        setIsLoading(false);
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const deletemember = async (userid) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/members/delete`, {
        userid,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        toast.success("Member Deleted Successfully!");
        return "success";
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    }
    setIsLoading(false);
  };

  const getallteams = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/teams/all`, { comp: '' });

      if (response.data.message === "success") {
        setIsLoading(false);
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getteams = async (comp) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/teams/all`, { comp });

      if (response.data.message === "success") {
        setIsLoading(false);
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getteam = async (teamid) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/teams/get`, {
        teamid,
      });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getteammatches = async (teamid) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/teams/matches`, {
        teamid,
      });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getcompteams = async (comp) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/teams/all`, { comp });

      if (response.data.message === "success") {
        setIsLoading(false);
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getmatchdays = async (competition) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/matches/matchdays/all`, {competition: competition});

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getdaymatches = async (matchday) => {
    try {
      const response = await axios.post(`${BASE_URL}/matches/matchdays/matches`, { matchday });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const getmatches = async (statusfilter) => {
    try {
      setIsLoading(true);
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/matches/all`, { statusfilter, userid: userToken.userid });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getmatch = async (matchid) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/matches/get`, {
        matchid,
      });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getrecentmatches = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/matches/recent`);

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const reviewmatch = async ({ matchid, reviewaction, reviewnote }) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/matches/review`, {
        matchid,
        reviewaction,
        reviewnote,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        toast.success("Match " + reviewaction + " Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const addmatch = async (competition, team1, team2, date, time, matchday, status) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/matches/add`, { competition, team1, team2, date, time, matchday, status });
      if (response.data.message === "success") {
        toast.success("Match Added Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Catch: " + error.response.data);
    } finally {
      setIsLoading(false);
    }
  };

  const updatematch = async (competition, team1, team2, date, time, matchday, status, matchId) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/matches/update`, { competition, team1, team2, date, time, matchday, status, matchid: matchId });

      if (response.data.message === "success") {
        toast.success("Match Updated Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const deletematch = async (matchid) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/matches/delete`, {
        matchid,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        toast.success("Match Deleted Successfully!");
        return "success";
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    }
    setIsLoading(false);
  };

  const addplayer = async (name, position, team) => {
    try {
      const response = await axios.post(`${BASE_URL}/players/add`, { name, position, team });
      if (response.data.message === "success") {
        toast.success("Player Added Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Catch: " + error.response.data);
    } finally {
    }
  };

  const updateplayer = async (name, position, playerid) => {
    try {
      const response = await axios.post(`${BASE_URL}/players/update`, { name, position, playerid });
      if (response.data.message === "success") {
        toast.success("Player Updated Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Catch: " + error.response.data);
    } finally {
    }
  };

  const getteamplayers = async (team) => {
    try {
      const response = await axios.post(`${BASE_URL}/players/all`, {
        team,
      });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const getplayer = async (playerid) => {
    try {
      const response = await axios.post(`${BASE_URL}/players/get`, {
        playerid,
      });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const deleteplayer = async (playerid) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/players/delete`, {
        playerid,
      });

      if (response.data.message === "success") {
        toast.success("Player Deleted Successfully!");
        return "success";
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    }
  };

  const switchplayer = async (playerid, team) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      const response = await axios.post(`${BASE_URL}/players/switch`, {
        playerid, team,
      });

      if (response.data.message === "success") {
        toast.success("Player Switched Successfully!");
        return "success";
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    }
  };

  const getlinkcategories = async (type, matchid) => {
    try {
      const response = await axios.post(`${BASE_URL}/linkcategories/all`, { type, matchid });

      if (response.data.message === "success") {
        setIsLoading(false);
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const getlinkcategory = async (catid) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/linkcategories/get`, {
        catid,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const addlinkcategory = async (name) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/linkcategories/add`, {
        name,
      });

      if (response.data.message === "success") {
        toast.success("Record Added Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const updatelinkcategory = async (name, catid) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/linkcategories/update`, {
        name,
        catid,
      });

      if (response.data.message === "success") {
        toast.success("Record Updated Successfully!");
        setIsLoading(false);
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const deletelinkcategory = async (catid) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/linkcategories/delete`, {
        catid,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        toast.success("Record Deleted Successfully!");
        return "success";
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    }
    setIsLoading(false);
  };


  const getmatchdaysdata = async (competition) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/matchdaydata/all`, { competition });
      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getmatchdaydata = async (matchday, competition) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/matchdaydata/get`, {
        matchday, competition,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const updatematchdaydata = async (matchday, competition, twitterNotified, twitterSuspended, tiktokNotified, tiktokSuspended, telegramNotified, telegramSuspended, telegramImpacted) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/matchdaydata/update`, {
        matchday, competition, twitterNotified, twitterSuspended, tiktokNotified, tiktokSuspended, telegramNotified, telegramSuspended, telegramImpacted
      });

      if (response.data.message === "success") {
        toast.success("Record Updated Successfully!");
        setIsLoading(false);
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getwebsitelinks = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/linkwebsites/all`);

      if (response.data.message === "success") {
        setIsLoading(false);
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const getwebsitelink = async (webid) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/linkwebsites/get`, {
        webid,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const addwebsitelink = async (name) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/linkwebsites/add`, {
        name,
      });

      if (response.data.message === "success") {
        toast.success("Record Added Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const updatewebsitelink = async (name, link, webid) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/linkwebsites/update`, {
        name, link, webid,
      });

      if (response.data.message === "success") {
        toast.success("Record Updated Successfully!");
        setIsLoading(false);
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const deletewebsitelink = async (webid) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/linkwebsites/delete`, {
        webid,
      });

      if (response.data.message === "success") {
        setIsLoading(false);
        toast.success("Record Deleted Successfully!");
        return "success";
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    }
    setIsLoading(false);
  };

  const addmatchlinks = async (input, type, matchid) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/matchlinks/add`, {
        input,
        type,
        matchid,
      });

      if (response.data.message === "success") {
        toast.success("Record Added Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getmatchlinks = async (type, matchid) => {
    try {
      const response = await axios.post(`${BASE_URL}/matchlinks/all`, {
        type,
        matchid,
      });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const getmatchlinkswebsites = async (matchid) => {
    try {
      const response = await axios.post(`${BASE_URL}/matchlinks/websites`, {
        matchid,
      });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const getmatchwebsites = async (matchid) => {
    try {
      const response = await axios.post(`${BASE_URL}/matches/websites`, {
        matchid,
      });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const getspecwebsites = async (matchid) => {
    try {
      const response = await axios.post(`${BASE_URL}/matches/specwebsites`, {
        matchid,
      });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const updatematchspectators = async (matchid, spectators) => {
    try {
      const response = await axios.post(`${BASE_URL}/matches/websites/update`, {
        matchid, spectators
      });

      if (response.data.message === "success") {
        toast.success("Record Updated Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const deletematchlink = async (id) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/matchlinks/delete`, {
        id,
      });

      if (response.data.message === "success") {
        toast.success("Record Deleted Successfully!");
        return "success";
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const addmatchscore = async (matchid, team1score, team2score, team1scorer, team2scorer) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/score/add`, {
        matchid, team1score, team2score, team1scorer, team2scorer
      });

      if (response.data.message === "success") {
        toast.success("Score Updated Successfully!");
        return response.data.message;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getteamscorers = async (team, matchid) => {
    try {
      const response = await axios.post(`${BASE_URL}/score/teamscorers`, {
        team, matchid
      });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const getoverview = async (competition, date) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/overview/all`, {competition, date});

      if (response.data.message === "success") {
        setIsLoading(false);
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getoverviewstats = async (competition, date, team) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/overview/stats`, {competition, date, team});

      if (response.data.message === "success") {
        setIsLoading(false);
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getoverviewdomain = async (competition, date, team) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/overview/domain`, {competition, date, team});

      if (response.data.message === "success") {
        setIsLoading(false);
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getreportmatchdays = async (competition) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/reports/matchdays/all`, {competition});

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const getoverviewdaymatches = async (matchday, competition) => {
    try {
      const response = await axios.post(`${BASE_URL}/reports/matchdays/matches`, { matchday, competition });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const getoverviewdaywebsites = async (matchday, competition) => {
    try {
      const response = await axios.post(`${BASE_URL}/reports/links/websites`, { matchday, competition });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const getoverviewmatchdaydata = async (matchday, competition) => {
    try {
      const response = await axios.post(`${BASE_URL}/reports/matchday/data`, { matchday, competition });

      if (response.data.message === "success") {
        return response.data.result;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    } finally {
    }
  };

  const updateprofile = async (name, email) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/user/update`, {
        name,
        email,
        userid: userToken.userid,
      });

      if (response.data.message === "success") {
        localStorage.setItem("userToken", JSON.stringify(response.data.userToken[0]));
        toast.success("Profile Updated Successfully!");
        setIsLoading(false);
        return "success";
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Request failed: " + error);
    }
    setIsLoading(false);
  };

  const updatepassword = async (oldpassword, newpassword, confirmpassword) => {
    try {
      if (newpassword === confirmpassword) {
        const userToken = JSON.parse(localStorage.getItem("userToken"));
        setIsLoading(true);
        const response = await axios.post(`${BASE_URL}/user/updatepassword`, {
          oldpassword,
          newpassword,
          confirmpassword,
          userid: userToken.userid,
        });

        if (response.data.message === "success") {
          toast.success("Password Updated Successfully!");
          setIsLoading(false);
          return "success";
        } else {
          toast.error(response.data.message);
        }
      } else {
        toast.error("Passwords don't match");
      }
    } catch (error) {
      toast.error("Request failed: " + error);
    }
    setIsLoading(false);
  };

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.clear();
    return "success";
  };

  return (
    <AppContext.Provider
      value={{
        userToken,
        isLoading,
        logout,
        getdashboard,
        getmembers,
        getmember,
        reviewmember,
        addmember,
        updatemember,
        deletemember,
        getallteams,
        getteams,
        getteam,
        getteammatches,
        getcompteams,
        getmatchdays,
        getdaymatches,
        getmatches,
        getmatch,
        getrecentmatches,
        addmatch,
        reviewmatch,
        updatematch,
        deletematch,
        addplayer,
        updateplayer,
        getteamplayers,
        getplayer,
        deleteplayer,
        switchplayer,
        getlinkcategories,
        getlinkcategory,
        addlinkcategory,
        updatelinkcategory,
        deletelinkcategory,
        getmatchdaysdata,
        getmatchdaydata,
        updatematchdaydata,
        getwebsitelinks,
        getwebsitelink,
        addwebsitelink,
        updatewebsitelink,
        deletewebsitelink,
        getmatchlinks,
        getmatchlinkswebsites,
        getmatchwebsites,
        getspecwebsites,
        updatematchspectators,
        addmatchlinks,
        deletematchlink,
        addmatchscore,
        getteamscorers,
        getoverview,
        getoverviewdomain,
        getreportmatchdays,
        getoverviewdaymatches,
        getoverviewdaywebsites,
        getoverviewmatchdaydata,
        getoverviewstats,
        updateprofile,
        updatepassword,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
