import React from 'react';
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import '../styles/datatable.css';
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight, MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';

const DataTable = ({ className, data, columns }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div className={`table-wrapper ${className}`}>
      <div className='flex justify-between items-center mb-2 mt-2'>
        <input className='table-search' type="text" value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Recherche..." />

        <select className='rows' value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Afficher {pageSize} lignes
            </option>
          ))}
        </select>
      </div>

      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      { data.length > 0 ? 
      <div className='flex justify-between items-center mt-5'>
        <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <MdKeyboardDoubleArrowLeft size={18}/>
          </button>
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            <MdKeyboardArrowLeft size={18}/>
          </button>
          <button className='cur'>
            <span>
              Page{' '}
              <span>
                {pageIndex + 1} de {pageOptions.length}
              </span>{' '}
            </span>
          </button>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            <MdKeyboardArrowRight size={18}/>
          </button>
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            <MdKeyboardDoubleArrowRight size={18}/>
          </button>
        </div>

        <div className="record-range">
          Affiche de {pageIndex * pageSize + 1} - {Math.min((pageIndex + 1) * pageSize, data.length)} à {data.length} enregistrés
        </div>
      </div>
      : 
      <div className='w-full p-10 border border-t-0 text-center text-neutral-400 text-sm'>Aucun enregistrement trouvé</div>
      }
    </div>
  );
};

export default DataTable;
