import React, { useContext, useState, useEffect } from "react";
import AppLayout from "../layouts/AppLayout";
import DataTable from "../components/DataTable";
import { AppContext } from "../contexts/AppContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import Swal from "sweetalert2";
import { HiArrowNarrowRight } from "react-icons/hi";
import { FiPlus } from "react-icons/fi";

const EditLinkCategories = () => {
  const { catId } = useParams();

  const { getlinkcategories, getlinkcategory, addlinkcategory, updatelinkcategory, deletelinkcategory, isLoading } = useContext(AppContext);

  const [categories, setCategories] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");
  const [name, setName] = useState("");

  const handleDelete = async (category) => {
    if (!isLoading) {
      try {
        const result = await Swal.fire({
          title: "Are you sure to delete?",
          text: "You won't be able to undo this action",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete",
        });

        if (result.isConfirmed) {
          let response = await deletelinkcategory(category);
          if (response === "success") {
            fetchCategories();
          }
        }
      } catch (error) {
        Swal.fire("An Error Occurred", error, "error");
      }
    }
  };

  const columns = React.useMemo(
    () => [
      { Header: "Name", accessor: "name" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex items-center space-x-2">
            {row.original.status !== "Completed" && (
              <Link className="text-edit" to={`/edit-link-categories/${row.original.id}`}>
                Edit
              </Link>
            )}
            <button className="text-delete" onClick={() => handleDelete(row.original.id)}>
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const fetchCategories = async () => {
    const response = await getlinkcategories();
    setCategories(response);
  };

  useEffect(() => {
    const fetchCat = async () => {
      if (catId) {
        const response = await getlinkcategory(catId);
        if (response.length > 0) {
          setName(response[0].name);
          setIsOpen(true);
        }
      }
    };

    fetchCat();
  }, [catId]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      try {
        if (name !== "") {
          setError("");

          let response = "";
          if (catId) {
            response = await updatelinkcategory(name, catId);
          } else {
            response = await addlinkcategory(name);
          }
          if (response === "success") {
            fetchCategories();
            setName("");
            setIsOpen(false);
            navigate("/edit-link-categories");
          }
        } else {
          setError("All fields are required");
          return;
        }
      } catch (error) {
        setError("Error: " + error);
      }
    }
  };

  return (
    <AppLayout pageName="Links Categories">
      <section>
        {!catId && (
          <div className="flex justify-between flex-wrap">
            <div className="mb-4">
              <button onClick={() => setIsOpen(!isOpen)} className="btn-main-inverse">
                New Category <FiPlus />
              </button>
            </div>
          </div>
        )}

        {isOpen && (
          <section className="w-full lg:w-1/2 !mb-4">
            <form onSubmit={handleSubmit} method="post">
              <div className="p-2">
                <label htmlFor="name">Category Name</label>
                <input id="name" name="name" type="text" className="form-control" required value={name} onChange={(e) => setName(e.target.value)} />
              </div>
              <div className="px-2 pb-2">
                {error !== "" ? <div className="text-left text-red-500 font-semibold text-md mb-2 tracking-wide">{error}</div> : <div className="py-4"></div>}
                <button type="submit" className="btn-submit" disabled={isLoading}>
                  <span>{catId ? "Sauvegarder les modifications" : "Submit"}</span>
                  <span>{isLoading === false ? <HiArrowNarrowRight /> : <Spinner size={14} className="ml-2" animating={isLoading} />}</span>
                </button>
              </div>
            </form>
          </section>
        )}

        {isLoading ? (
          <div className="flex justify-center w-full py-12">
            <Spinner color="#CCCCCC" size={24} />
          </div>
        ) : (
          <DataTable data={categories} columns={columns} />
        )}
      </section>
    </AppLayout>
  );
};

export default EditLinkCategories;
