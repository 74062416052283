import React, { useContext, useState, useEffect } from "react";
import AppLayout from "../layouts/AppLayout";
import DataTable from "../components/DataTable";
import { AppContext } from "../contexts/AppContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import Swal from "sweetalert2";
import { HiArrowNarrowRight } from "react-icons/hi";
import { FiPlus } from "react-icons/fi";
import { FaSquareXTwitter } from "react-icons/fa6";
import "../styles/matchday.css";


const EditMatchdayData = () => {
  const { matchDay } = useParams();

  const { getwebsitelinks, getwebsitelink, addwebsitelink, updatewebsitelink, deletewebsitelink, getmatchdaysdata, getmatchdaydata, updatematchdaydata, isLoading } = useContext(AppContext);

  const [matchdayData, setMatchdayData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [slctdCompetition, setslctdCompetition] = useState('1');
  const [matchdays, setMatchdays] = useState([]);
  const [matchday, setMatchday] = useState();
  const [twitterNotified, setTwitterNotified] = useState(0);
  const [twitterSuspended, setTwitterSuspended] = useState(0);
  const [tiktokNotified, setTiktokNotified] = useState(0);
  const [tiktokSuspended, setTiktokSuspended] = useState(0);
  const [telegramNotified, setTelegramNotified] = useState(0);
  const [telegramSuspended, setTelegramSuspended] = useState(0);
  const [telegramImpacted, setTelegramImpacted] = useState(0);

  const fetchMatchDaysData = async () => {
    const response = await getmatchdaysdata(slctdCompetition);
    setMatchdays(response);
  };

  useEffect(() => {
    fetchMatchDaysData();
  }, [slctdCompetition]);

  const columns = React.useMemo(
    () => [
      { Header: "Journée", accessor: "matchday" },
    //   { Header: "Domains", accessor: "link" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex items-center space-x-2">
              <Link className="text-edit" to={`/edit-matchday-data/${row.original.matchday}`}>
                Edit
              </Link>
          </div>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    const fetchdata = async () => {
      if (matchDay) {
        const response = await getmatchdaydata(matchDay, slctdCompetition);
          setMatchdayData(response);
          if(response.length > 0){
            setTwitterNotified(response[0].accnotifiedtwitter);
            setTwitterSuspended(response[0].accsuspendedtwitter);
            setTiktokNotified(response[0].accnotifiedtiktok);
            setTiktokSuspended(response[0].accsuspendedtiktok);
            setTelegramNotified(response[0].accnotifiedtelegram);
            setTelegramSuspended(response[0].accsuspendedtelegram);
            setTelegramImpacted(response[0].membersinspactedtelegram);
          }
          setIsOpen(true);
      }
    };

    if(matchDay){
      fetchdata();
    }
    else{
      setIsOpen(false);
    }
  }, [matchDay]);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      try {
          setError("");
          let response = "";
          if (matchDay) {
            response = await updatematchdaydata(matchDay, slctdCompetition, twitterNotified, twitterSuspended, tiktokNotified, tiktokSuspended, telegramNotified, telegramSuspended, telegramImpacted);
          } else {
            // response = await addwebsitelink(name);
          }
          if (response === "success") {
            fetchMatchDaysData();
            setIsOpen(false);
            navigate("/edit-matchday-data");
          }
      } catch (error) {
        setError("Error: " + error);
      }
    }
  };

  const competitions = ['1', '2'];

  return (
    <AppLayout pageName="Matchday Data">
      <section>

        <div className="flex flex-wrap flex-col lg:flex-row justify-between">
            <div class="inline-flex rounded-md mb-3">
            { competitions.map((competition, index) => {
            return (
                <button onClick={() => setslctdCompetition(competition)} aria-current="page" class={`px-4 py-2 text-sm font-medium tracking-wider ${competition === slctdCompetition ? 'text-white bg-main' : 'text-neutral-800 bg-white border border-gray-200 hover:bg-gray-100'}`}>
                    { 'Ligue '+competition }
                </button>
            )
            }) }
            </div>
        </div>

        {isOpen && (
          <section className="w-full !mb-4">
            <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-5" method="post">
              <div className="p-2">
                <label htmlFor="twitterNotified">Accounts Notified - <b className="text-main-alt">Twitter</b></label>
                <div className="relative">
                  <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                    <img alt="twitter" src="/images/icons/x.png" className="w-6 h-6"/>
                  </div>
                  <input id="twitterNotified" name="twitterNotified" type="number" className="form-control !pl-12" required value={twitterNotified} onChange={(e) => setTwitterNotified(e.target.value)} />
                </div>
              </div>
              <div className="p-2">
                <label htmlFor="twitterSuspended">Accounts Suspended - <b className="text-main-alt">Twitter</b></label>
                <div className="relative">
                  <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                    <img alt="twitter" src="/images/icons/x.png" className="w-6 h-6"/>
                  </div>
                  <input id="twitterSuspended" name="twitterSuspended" type="number" className="form-control !pl-12" required value={twitterSuspended} onChange={(e) => setTwitterSuspended(e.target.value)} />
                </div>
              </div>


              <div className="p-2">
                <label htmlFor="tiktokNotified">Accounts Notified - <b className="text-main-alt">Tiktok</b></label>
                <div className="relative">
                  <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                    <img alt="twitter" src="/images/icons/tiktok.png" className="w-6 h-6"/>
                  </div>
                  <input id="tiktokNotified" name="tiktokNotified" type="number" className="form-control !pl-12" required value={tiktokNotified} onChange={(e) => setTiktokNotified(e.target.value)} />
                </div>
              </div>
              <div className="p-2">
                <label htmlFor="tiktokSuspended">Accounts Suspended - <b className="text-main-alt">Tiktok</b></label>
                <div className="relative">
                  <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                    <img alt="twitter" src="/images/icons/tiktok.png" className="w-6 h-6"/>
                  </div>
                  <input id="tiktokSuspended" name="tiktokSuspended" type="number" className="form-control !pl-12" required value={tiktokSuspended} onChange={(e) => setTiktokSuspended(e.target.value)} />
                </div>
              </div>

              
              <div className="p-2">
                <label htmlFor="telegramNotified">Accounts Notified - <b className="text-main-alt">Telegram</b></label>
                <div className="relative">
                  <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                    <img alt="twitter" src="/images/icons/telegram.png" className="w-6 h-6"/>
                  </div>
                  <input id="telegramNotified" name="telegramNotified" type="number" className="form-control !pl-12" required value={telegramNotified} onChange={(e) => setTelegramNotified(e.target.value)} />
                </div>
              </div>
              <div className="p-2">
                <label htmlFor="telegramSuspended">Accounts Suspended - <b className="text-main-alt">Telegram</b></label>
                <div className="relative">
                  <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                    <img alt="twitter" src="/images/icons/telegram.png" className="w-6 h-6"/>
                  </div>
                  <input id="telegramSuspended" name="telegramSuspended" type="number" className="form-control !pl-12" required value={telegramSuspended} onChange={(e) => setTelegramSuspended(e.target.value)} />
                </div>
              </div>
              <div className="p-2">
                <label htmlFor="telegramImpacted">Impacted Members - <b className="text-main-alt">Telegram</b></label>
                <div className="relative">
                  <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                    <img alt="twitter" src="/images/icons/telegram.png" className="w-6 h-6"/>
                  </div>
                  <input id="telegramImpacted" name="telegramImpacted" type="number" className="form-control !pl-12" required value={telegramImpacted} onChange={(e) => setTelegramImpacted(e.target.value)} />
                </div>
              </div>
              <div></div>

              <div className="px-2 pb-2">
                {error !== "" ? <div className="text-left text-red-500 font-semibold text-md mb-2 tracking-wide">{error}</div> : <div className="py-4"></div>}
                <div className="flex space-x-3">
                  <button type="submit" className="btn-submit" disabled={isLoading}>
                    <span>{matchDay ? "Sauvegarder les modifications" : "Submit"}</span>
                    <span>{isLoading === false ? <HiArrowNarrowRight /> : <Spinner size={14} className="ml-2" animating={isLoading} />}</span>
                  </button>
                  { matchDay && <Link to={'/edit-matchday-data'} className="btn-cancel">Cancel</Link> }
                </div>
              </div>
            </form>
          </section>
        )}

        {isLoading ? (
          <div className="flex justify-center w-full py-12">
            <Spinner color="#CCCCCC" size={24} />
          </div>
        ) : (
          <>
          <table class="table text-center mt-3">
          <thead>
              <tr>
                  <th></th>
                  <th colSpan={2}>Twitter</th>
                  <th colSpan={2}>Tiktok</th>
                  <th colSpan={3}>Telegram</th>
                  <th></th>
              </tr>
              <tr>
                <th rowSpan={2} scope="col">
                    MatchDay
                </th>
                <th>Accounts Notified</th>
                <th>Accounts Suspended</th>
                <th>Accounts Notified</th>
                <th>Accounts Suspended</th>
                <th>Accounts Notified</th>
                <th>Accounts Suspended</th>
                <th>Impacted Members</th>
                <th rowSpan={2} scope="col">
                    Actions
                </th>
              </tr>
          </thead>
          <tbody>
          {
          matchdays.map((matchday, index) => {
            return(
                <tr key={index} className="border-t font-medium">
                    <td>{matchday.matchday}</td>
                    <td>{matchday.accnotifiedtwitter}</td>
                    <td>{matchday.accsuspendedtwitter}</td>
                    <td>{matchday.accnotifiedtiktok}</td>
                    <td>{matchday.accsuspendedtiktok}</td>
                    <td>{matchday.accnotifiedtelegram}</td>
                    <td>{matchday.accsuspendedtelegram}</td>
                    <td>{matchday.membersinspactedtelegram}</td>
                    <td>
                      <Link className="text-edit" to={`/edit-matchday-data/${matchday.matchday}`}>
                        Edit
                      </Link>
                    </td>
                </tr>
            )
          })
          }
          </tbody>
          </table>
          </>
        )}
      </section>
    </AppLayout>
  );
};

export default EditMatchdayData;
