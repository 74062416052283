import React, { Fragment, useContext, useState, useEffect } from "react";
import AppLayout from "../layouts/AppLayout";
import DataTable from "../components/DataTable";
import { AppContext } from "../contexts/AppContext";
import { Dialog, Transition } from "@headlessui/react";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import Swal from "sweetalert2";
import { HiArrowNarrowRight } from "react-icons/hi";

const AllTeams = () => {
  const { getteams, isLoading } = useContext(AppContext);

  const [ligue1, setLigue1] = useState([]);
  const [ligue2, setLigue2] = useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name", // You can use any accessor name here
        // Define the cell content and actions buttons
        Cell: ({ row }) => (
          <div className="flex space-x-2 text-base font-medium items-center text-neutral-800">
            <img width="30" height="30" src={`/images/teams/${row.original.name.toLowerCase().replaceAll(" ", "")}.png`} /> <span>{row.original.name}</span>
          </div>
        ),
      },
      { Header: "Status", accessor: "status" },
    ],
    []
  );

  const fetchTeams = async () => {
    const response = await getteams(1);
    setLigue1(response);

    const response2 = await getteams(2);
    setLigue2(response2);
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  return (
    <AppLayout pageName="Toutes les équipes">
      {isLoading ? (
        <section>
          <div className="flex justify-center w-full py-12">
            <Spinner color="#CCCCCC" size={24} />
          </div>
        </section>
      ) : (
        <>
          <section>
            <div className="w-full md:w-1/2 mb-4">
              <img height="100" className="rounded-lg" src={`/images/teams/ligue1.png`} />
            </div>
            <div className="grid grid-cols-2 gap-4">
              {ligue1.map((team, index) => {
                return (
                  <Link to={`/teams/${team.name.toLowerCase().replaceAll(" ", "")}/${team.id}`}>
                    <div className={`p-4 hover:bg-neutral-50 border-2 rounded-lg border-neutral-100 cursor-pointer`}>
                      <div className="flex space-x-2 text-base font-medium items-center text-neutral-800">
                        <img width="30" height="30" src={`/images/teams/${team.name.toLowerCase().replaceAll(" ", "")}.png`} /> <span>{team.name}</span>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </section>
          <section>
            <div className="w-full md:w-1/2 mb-4">
              <img height="100" className="rounded-lg" src={`/images/teams/ligue2.png`} />
            </div>
            <div className="grid grid-cols-2 gap-4">
              {ligue2.map((team, index) => {
                return (
                  <Link to={`/teams/${team.name.toLowerCase().replaceAll(" ", "")}/${team.id}`}>
                    <div className={`p-4 hover:bg-neutral-50 border-2 rounded-lg border-neutral-100 cursor-pointer`}>
                      <div className="flex space-x-2 text-base font-medium items-center text-neutral-800">
                        <img width="30" height="30" src={`/images/teams/${team.name.toLowerCase().replaceAll(" ", "")}.png`} /> <span>{team.name}</span>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </section>
        </>
      )}
    </AppLayout>
  );
};

export default AllTeams;
