import AppLayout from "../layouts/AppLayout";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { AppContext } from "../contexts/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import { PiUsersThree } from "react-icons/pi";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { HiArrowNarrowRight } from "react-icons/hi";
import { FaCheck, FaTimes } from "react-icons/fa";

const AddMatch = () => {
  const { matchId } = useParams();

  const [pageLoading, setpageLoading] = useState(false);
  const [match, setMatch] = useState(null);
  const [teams, setTeams] = useState([]);
  const [competition, setCompetition] = useState("");
  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [matchday, setMatchday] = useState("");
  const [status, setStatus] = useState("Scheduled");
  const [error, setError] = useState("");

  const { userToken, getteams, getmatch, addmatch, updatematch, isLoading } = useContext(AppContext);

  const selectType = async (comp) => {
    setCompetition(comp);
    setpageLoading(true);
    const response = await getteams(comp);
    setTeams(response);
    setpageLoading(false);
  };

  useEffect(() => {
    const fetchMatch = async () => {
      if (matchId) {
        const response = await getmatch(matchId);
        if (response.length > 0) {
          setMatch(response[0]);
          setStatus(response[0].status);
          setCompetition(response[0].competition);
          setTeam1(response[0].team1);
          setTeam2(response[0].team2);
          setDate(response[0].date);
          setTime(response[0].time);
          setMatchday(response[0].matchday);
          const response2 = await getteams(response[0].competition);
          setTeams(response2);
        }
      }
    };

    fetchMatch();
  }, [matchId]);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      try {
        if (competition !== "" && team1 !== "" && team2 !== "" && date !== "" && time !== "") {
          setError("");

          let response = "";
          if (matchId) {
            response = await updatematch(competition, team1, team2, date, time, matchday, status, matchId);
          } else {
            response = await addmatch(competition, team1, team2, date, time, matchday, status);
          }
          if (response === "success") {
            navigate("/all-matches");
          }
        } else {
          setError("All fields are required");
          return;
        }
      } catch (error) {
        setError("Error: " + error);
      }
    }
  };

  return (
    <AppLayout pageName="Add New Match">
      <section>
        <div className="w-full p-2">
          <label>Select Competition</label>
          <div className="grid grid-cols-2 gap-4">
            <img className={`w-full rounded-lg ${competition !== 1 && "opacity-60 hover:opacity-90"}`} onClick={() => selectType(1)} src={`/images/teams/ligue1.png`} />
            <img className={`w-full rounded-lg ${competition !== 2 && "opacity-60 hover:opacity-90"}`} onClick={() => selectType(2)} src={`/images/teams/ligue2.png`} />
          </div>
        </div>
        {competition !== "" && (
          <>
            {pageLoading ? (
              <div className="flex justify-center w-full py-12">
                <Spinner color="#CCCCCC" size={24} />
              </div>
            ) : (
              <form onSubmit={handleSubmit} method="post" encType="multipart/form-data" className={`flex flex-row flex-wrap w-full`}>
                <div className="w-2/4 p-2">
                  <label htmlFor="team1">Team 1</label>
                  <select id="team1" name="team1" className="form-control" value={team1} onChange={(e) => setTeam1(e.target.value)} required>
                    <option disabled selected value="">
                      Select
                    </option>
                    {teams.map((team) => (
                      <option key={team.id} value={team.id}>
                        {team.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-2/4 p-2">
                  <label htmlFor="team2">Team 2</label>
                  <select id="team2" name="team2" className="form-control" value={team2} onChange={(e) => setTeam2(e.target.value)} required>
                    <option disabled selected value="">
                      Select
                    </option>
                    {teams.map((team) => (
                      <option key={team.id} value={team.id}>
                        {team.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-2/4 p-2">
                  <label htmlFor="date">Date</label>
                  <input id="date" name="date" required type="date" className="form-control" value={date} onChange={(e) => setDate(e.target.value)} />
                </div>
                <div className="w-2/4 p-2">
                  <label htmlFor="time">Time</label>
                  <input id="time" name="time" required type="time" className="form-control" value={time} onChange={(e) => setTime(e.target.value)} />
                </div>
                <div className="w-2/4 p-2">
                  <label htmlFor="matchday">Matchday (optional)</label>
                  <input id="matchday" name="matchday" type="text" className="form-control" value={matchday} onChange={(e) => setMatchday(e.target.value)} />
                </div>
                <div className="w-2/4 p-2">
                  <label htmlFor="email">Match Status</label>
                  <select id="status" name="status" className="form-control" value={status} defaultValue="Scheduled" onChange={(e) => setStatus(e.target.value)}>
                    <option selected value="Scheduled">
                      Scheduled
                    </option>
                    <option value="Postponed">Postponed</option>
                    <option value="Suspended">Suspended</option>
                  </select>
                </div>
                <div className="px-2 pb-2">
                  {error !== "" ? <div className="text-left text-red-500 font-semibold text-md mb-2 tracking-wide">{error}</div> : <div className="py-4"></div>}
                  {status === "Under Review" ? (
                    ""
                  ) : (
                    <button type="submit" className="btn-submit" disabled={isLoading}>
                      <span>{matchId ? "Sauvegarder les modifications" : "Submit"}</span>
                      <span>{isLoading === false ? <HiArrowNarrowRight /> : <Spinner size={14} className="ml-2" animating={isLoading} />}</span>
                    </button>
                  )}
                </div>
              </form>
            )}
          </>
        )}
      </section>
    </AppLayout>
  );
};

export default AddMatch;
