import React, { useState } from 'react';

const Tooltip = ({ children, text, customClass }) => {
    const [isShown, setIsShown] = useState(false);

    return (
        <div className="relative flex items-center">
            <div
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)}
            >
                {children}
            </div>
            <div 
                className={`tooltip ${customClass} ${isShown ? 'opacity-100' : 'opacity-0'}`}
                style={{ pointerEvents: isShown ? 'auto' : 'none' }} dangerouslySetInnerHTML={{ __html: text }}
            >
            </div>
        </div>
    );
};

export default Tooltip;
