import React, { Fragment, useContext, useState, useEffect } from "react";
import AppLayout from "../layouts/AppLayout";
import DataTable from "../components/DataTable";
import { AppContext } from "../contexts/AppContext";
import { Dialog, Transition } from "@headlessui/react";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import Swal from "sweetalert2";
import { HiArrowNarrowRight } from "react-icons/hi";

const AllMembers = () => {
  const { getmembers, deletemember, isLoading } = useContext(AppContext);

  const [members, setMembers] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");

  const handleDelete = async (todelete) => {
    if (!isLoading) {
      try {
        const result = await Swal.fire({
          title: "Are you sure to delete?",
          text: "You won't be able to undo this action",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Delete",
        });

        if (result.isConfirmed) {
          let response = await deletemember(todelete);
          if (response === "success") {
            fetchMembers();
          }
        }
      } catch (error) {
        Swal.fire("An Error Occurred", error, "error");
      }
    }
  };

  const columns = React.useMemo(
    () => [
      { Header: "Name", accessor: "name" },
      { Header: "Adresse e-mail", accessor: "email" },
      { Header: "Status", accessor: "status" },
      {
        Header: "Actions",
        accessor: "actions", // You can use any accessor name here
        // Define the cell content and actions buttons
        Cell: ({ row }) => (
          <div>
            <Link className="text-edit" to={`/edit-member/${row.original.userid}`}>
              Edit
            </Link>
            &nbsp;
            <button className="text-delete" onClick={() => handleDelete(row.original.userid)}>
              Delete
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const fetchMembers = async () => {
    const response = await getmembers(statusFilter);
    setMembers(response);
  };

  useEffect(() => {
    fetchMembers();
  }, [statusFilter]);

  return (
    <AppLayout pageName="All Members">
      <section>
        <div className="flex justify-between flex-wrap">
          <div className="mb-4">
            <label htmlFor="statusFilter" className="mb-1">
              Filter by Status
            </label>
            <select id="statusFilter" name="statusFilter" className="form-control" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
              <option value="">All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
          <div className="mb-4">
            <Link className="inline-block" to="/add-member">
              <button className="btn-main-inverse">
                Add new Member <HiArrowNarrowRight />
              </button>
            </Link>
          </div>
        </div>

        {isLoading ? (
          <div className="flex justify-center w-full py-12">
            <Spinner color="#CCCCCC" size={24} />
          </div>
        ) : (
          <DataTable data={members} columns={columns} />
        )}
      </section>
    </AppLayout>
  );
};

export default AllMembers;
