import React, { useContext, useEffect, useState } from "react";
import "../styles/dashboard.css";
import AppLayout from "../layouts/AppLayout";
import DataTable from "../components/DataTable";
import { AppContext } from "../contexts/AppContext";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { Link } from "react-router-dom";
import { CiBoxList } from "react-icons/ci";
import { format } from "date-fns"; import { fr } from 'date-fns/locale';

const Dashboard = () => {
  const { getdashboard, userToken, getrecentmatches, isLoading } = useContext(AppContext);
  const [stats, setStats] = useState([]);
  const [matches, setMatches] = useState([]);

  const fetchDashboard = async () => {
    const response = await getdashboard();
    setStats(response);
  };

  const fetchRecentMatches = async () => {
    if(userToken.type === "Admin"){
      const response = await getrecentmatches();
      setMatches(response);
    }
  };

  useEffect(() => {
      fetchDashboard();
      fetchRecentMatches();
  }, []);

  if(userToken.type === "Admin"){
  }
  else if(userToken.type === "Customer"){
  }

  

  const columns = React.useMemo(
    () => [
      {
        Header: "Team 1",
        accessor: "team1",
        Cell: ({ row }) => {
          return (
            <div className="flex space-x-2 text-base font-medium items-center text-neutral-800">
              <img width="25" height="25" src={`/images/teams/${row.original.team1name.toLowerCase().replaceAll(" ", "")}.png`} /> <span>{row.original.team1name}</span>
            </div>
          );
        },
      },
      {
        Header: "Team 2",
        accessor: "team2",
        Cell: ({ row }) => {
          return (
            <div className="flex space-x-2 text-base font-medium items-center text-neutral-800">
              <img width="25" height="25" src={`/images/teams/${row.original.team2name.toLowerCase().replaceAll(" ", "")}.png`} /> <span>{row.original.team2name}</span>
            </div>
          );
        },
      },
      { Header: "Competition", accessor: "compname" },
      { Header: "Journée", accessor: "matchday" },
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ row }) => format(new Date(row.original.date), "EEE, do MMM yyyy", { locale: fr })
      },
      { Header: "Time", accessor: "time" },
      { Header: "Status", accessor: "status" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className="flex items-center space-x-2">
            <Link className="block btn-main-alt-inv !py-1 !px-3 !rounded-sm !text-[12px]" to={`/match-details/${row.original.matchid}`}>
              View Details
            </Link>
          </div>
        ),
      },
      { Header: "Added At", accessor: "addedatdate" },
    ],
    []
  );

  const StatBox = ({ value, label, info, link }) => (
    <Link to={link}>
      <section className="!mb-0 !shadow-lg h-full">
        <div className="hidden md:flex">
          <span className="p-3 bg-neutral-200 rounded-full">
            <CiBoxList size={22} />
          </span>
        </div>
        <h6 className="text-sm text-neutral-500 font-medium mt-0 md:mt-3 mb-1" dangerouslySetInnerHTML={{ __html: label }}></h6>
        <h2 className="text-4xl font-bold mb-0 md:mb-1">{value}</h2>
        {info && <span className="px-2 py-1 bg-neutral-200 text-neutral-500 rounded-sm text-xs font-medium leading-none">&bull;&nbsp; {info}</span>}
      </section>
    </Link>
  );

  const CustStatBox = ({ value, img, label, info, link }) => (
    <Link to={link}>
      <section className="!mb-0 !p-5 md:!p-7 !shadow-lg h-full">
        {img && <img className="h-10 md:h-20 bg-main-alt rounded-lg" src={img}/>}
        <h6 className="text-2xl md:text-4xl lg:text-5xl text-main font-bold mt-2 md:mt-4 mb-1" dangerouslySetInnerHTML={{ __html: label }}></h6>
        {value && <h2 className="text-3xl font-bold">{value}</h2>}
        {info && <div className="mt-1 md:mt-5 mb-0 md:mb-3 flex"><div className=" text-neutral-500 rounded-md text-base font-medium">{info}</div></div>}
      </section>
    </Link>
  );

  return (
    <AppLayout pageName={"Accueil"}>
      {stats !== "" ? (
        <>
          {userToken.type === "Admin" && (
            <>
              <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 max-sm:mt-1 gap-5">
                <StatBox link="/all-matches" value={stats.matchestoday} label="Matches Today" />
                <StatBox link="/all-matches" value={stats.matchestomorrow} label="Matches Tomorrow" />
                <StatBox link="/all-matches" value={stats.matches} label="Total Matches" />
                <StatBox link="/all-matches" value={stats.matchesreview} label="Matches to Review" />
                <StatBox link="/all-members" value={stats.members} label="Total Members" />
                <StatBox link="/all-members" value={stats.activemembers} label="Active Members" />
              </div>
              <section className="mt-5">
                <h2 className="text-xl mb-3 font-semibold text-neutral-900 tracking-normal">Upcoming Matches</h2>
                <div>
                  <DataTable data={matches} columns={columns} />
                </div>
              </section>
            </>
          )}

          {userToken.type === "Member" && (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 max-sm:mt-1 gap-3">
                <CustStatBox link="/matches/Ligue 1" img="/images/ligue1-sm.png" label="Ligue 1" info="Voir les rapports détaillés des matchs de Ligue 1"/>
                <CustStatBox link="/matches/Ligue 2" img="/images/ligue2-sm.png" label="Ligue 2" info="Voir les rapports détaillés des matchs de Ligue 2"/>
                <CustStatBox link="/overview" img="/images/overview.png" label="Aperçu" info="Résumé détaillés des domaines et contenus notifiés"/>
                <CustStatBox link="/report" img="/images/reports.png" label="Rapports" info="Résumés et détails de la journée"/>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="flex justify-center w-full py-12">
          <Spinner color="#CCCCCC" size={24} />
        </div>
      )}
    </AppLayout>
  );
};

export default Dashboard;
