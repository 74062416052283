import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import AuthLayout from "../layouts/AuthLayout";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [mailsent, setMailsent] = useState(false);
  const [error, setError] = useState("");

  const { forgotpassword, isLoading } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading === false) {
      try {
        if (email !== "") {
          setError("");
          const response = await forgotpassword(email);
          if (response === "success") {
            setMailsent(true);
          }
        } else {
          setError("All fields are required");
          return;
        }
      } catch (error) {
        setError("failed: " + error);
      }
    }
  };

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit} className="login-form">
        {mailsent === false ? (
          <>
            <h3 className="heading">Mot de passe oublié?</h3>
            <h6>
            Pas de problème, cela nous arrive à tous
              <br />
              Veuillez entrer l'adresse e-mail ci-dessous
            </h6>
            <hr className="bg-light" />
            <div>
              <div className="form-group email">
                <label htmlFor="email">Adresse e-mail</label>
                <div className="input-icon">
                  <img src="/images/icons/email.png" />
                </div>
                <input id="email" name="email" required type="email" className="form-control" placeholder="hello@protectartists.com" onChange={(e) => setEmail(e.target.value)} value={email} />
              </div>
              {error !== "" ? <div className="text-center text-red-500 font-semibold text-md my-2">{error}</div> : <div className="py-5"></div>}
              <button type="submit" className="w-full btn btn-main-alt" disabled={isLoading}>
                {isLoading === false ? "Soumettre" : <Bounce animating={isLoading} />}
              </button>
              <div className="text-center mt-5 form-info_text">
                <span>Vous connaissez déjà le mot de passe ? </span>{" "}
                <Link className="text-main font-weight-500" to="/">
                Connectez-vous plutôt
                </Link>
              </div>
            </div>
          </>
        ) : (
          <>
            <h3 className="heading !text-4xl !mb-4">E-mail de récupération envoyé !</h3>
            <h6 className="!text-lg">
            Merci de consulter vos emails. Nous avons envoyé des instructions de récupération de mot de passe.
              <br />
              <br />
              Le lien de récupération expirera dans 5 minutes.
            </h6>
            <div className="btn-main w-full uppercase text-center mt-10">Merci</div>
          </>
        )}
      </form>
    </AuthLayout>
  );
};

export default ForgotPassword;
