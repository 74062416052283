import React, { useContext, useRef, useEffect, useState, useMemo } from "react";
import AppLayout from "../layouts/AppLayout";
import DataTable from "../components/DataTable";
import { AppContext } from "../contexts/AppContext";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { DateRange } from 'react-date-range';
import {subYears, format} from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const Overview = () => {
  const { getoverview, getoverviewstats, getoverviewdomain, getallteams, getteams, isLoading } = useContext(AppContext);
  const [stats, setStats] = useState([]);
  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState("");
  const [domainstats, setdomainStats] = useState([]);
  const [slctdCompetition, setslctdCompetition] = useState('');
  const [totaldataChart, settotaldataChart] = useState();
  const [totaldataChartOptions, settotaldataChartOptions] = useState();
  const [domaindataChart, setdomaindataChart] = useState();
  const [domaindataChartOptions, setdomaindataChartOptions] = useState();
  const [range, setRange] = useState({
    startDate: subYears(new Date(), 1),
    endDate: new Date(),
    key: 'selection',
  });
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef(null);

  useEffect(() => {

      const fetchOverview = async () => {
        const response = await getoverview(slctdCompetition, range);
        // setStats(response);

        const data = {
            labels: response.map(stat => stat.name), // Assuming 'name' is the category label
            datasets: [
              {
                label: 'Total des liens',
                data: response.map(stat => stat.totallinks),
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
              },
              {
                label: 'Liens Direct',
                data: response.map(stat => stat.totallivelinks),
                backgroundColor: 'rgba(15, 157, 88, 0.5)',
              },
              {
                label: 'Liens Highlight',
                data: response.map(stat => stat.totalhighlightlinks),
                backgroundColor: 'rgba(59, 53, 97, 0.5)',
              },
              {
                label: 'Liens Google',
                data: response.map(stat => stat.totalgooglelinks),
                backgroundColor: 'rgba(244, 180, 0, 0.5)',
              },
            ],
          };
        
        const options = {
            maintainAspectRatio: false,
            scales: {
              x: {
                stacked: false,
              },
              y: {
                stacked: false,
                beginAtZero: true,
              },
            },
          };

        settotaldataChart(data);
        settotaldataChartOptions(options);

      };

      const fetchOverviewDomain = async () => {
        const response = await getoverviewdomain(slctdCompetition, range, team);
        setdomainStats(response);

        const data = {
          labels: response.map(stat => (stat.name)),
          datasets: [
            {
              label: 'Total des liens',
              data: response.map(stat => stat.totallinks),
              backgroundColor: 'rgba(54, 162, 235, 0.5)',
            },
            {
              label: 'Liens Direct',
              data: response.map(stat => stat.totallivelinks),
              backgroundColor: 'rgba(15, 157, 88, 0.5)',
            },
            {
              label: 'Liens Highlight',
              data: response.map(stat => stat.totalhighlightlinks),
              backgroundColor: 'rgba(59, 53, 97, 0.5)',
            },
            {
              label: 'Liens Google',
              data: response.map(stat => stat.totalgooglelinks),
              backgroundColor: 'rgba(244, 180, 0, 0.5)',
            },
          ],
        };
        
        const options = {
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: false,
            },
            y: {
              stacked: false,
              beginAtZero: true,
            },
          },
        };

        setdomaindataChart(data);
        setdomaindataChartOptions(options);

      };

      const fetchOverviewStats = async () => {
        const response = await getoverviewstats(slctdCompetition, range, team);
        setStats(response[0]);
      };

      const fetchTeams = async () => {
        const response = await getteams(slctdCompetition);
        setTeams(response);
      };

      // fetchOverview();
      fetchOverviewDomain();
      fetchOverviewStats();
      fetchTeams();

  }, [slctdCompetition, range, team]);

  const columns = React.useMemo(
    () => [
      { Header: "Category", accessor: "name" },
      { Header: "Total des liens", accessor: "totallinks" },
      { Header: "Total Direct", accessor: "totallivelinks" },
      { Header: "Total Highlights", accessor: "totalhighlightlinks" },
      { Header: "Total Google", accessor: "totalgooglelinks" },
    ],
    []
  );

  const domaincolumns = useMemo(() => {
    let columns = [
      { Header: "Domain", accessor: "name" },
      { Header: "Total des liens", accessor: "totallinks" }
    ];

    if (team !== '') {
      columns = [
        ...columns,
        { Header: "Home Links", accessor: "teamhomelinks" },
        { Header: "Away Links", accessor: "teamawaylinks" }
      ];
    }

    columns = [
      ...columns,
      { Header: "Total Direct", accessor: "totallivelinks" },
      { Header: "Total Highlights", accessor: "totalhighlightlinks" },
      { Header: "Total Google", accessor: "totalgooglelinks" },
    ];

    return columns;
  }, [team]); 

  const competitions = ['', 'Ligue 1', 'Ligue 2'];

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const StatBox = ({ value, label, info }) => (
    <section className="!mb-0 !p-5">
      <h6 className="text-sm text-neutral-500 font-medium mb-1" dangerouslySetInnerHTML={{ __html: label }}></h6>
      <h2 className="text-3xl font-bold">{value}</h2>
      {info && <span className="px-2 py-1 bg-neutral-200 text-neutral-500 rounded-sm text-xs font-medium leading-none">&bull;&nbsp; {info}</span>}
    </section>
  );

  return (
    <AppLayout pageName={"Aperçu"}>
      {isLoading ? (
        <div className="flex justify-center w-full py-12">
          <Spinner color="#CCCCCC" size={24} />
        </div>
      ) : (
        <>
        <div className="space-y-5">
            <section>
            <div className="flex flex-wrap flex-col lg:flex-row justify-between">

            <div class="inline-flex rounded-md mb-3">
            { competitions.map((competition, index) => {
              return (
                <button onClick={() => setslctdCompetition(competition)} aria-current="page" class={`px-4 py-2 text-sm font-medium tracking-wider ${competition === slctdCompetition ? 'text-white bg-main' : 'text-neutral-800 bg-white border border-gray-200 hover:bg-gray-100'}`}>
                    { competition === '' ? 'Toutes les compétitions' : competition }
                </button>
              )
            }) }
            </div>

            <div className="flex flex-col md:flex-row space-x-0 md:space-x-3 space-y-3 md:space-y-0">
              <div>
                <div className="inline-flex">
                <span class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-200 border-e-0 rounded-s-md">
                  Filtrer par équipes
                </span>
                <select id="team" name="team" className="bg-white rounded-none rounded-e-lg border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-200 p-2.5 outline-none" value={team} onChange={(e) => setTeam(e.target.value)} required>
                  <option value="">
                    Toutes les équipes
                  </option>
                  {teams.map((team) => (
                    <option key={team.id} value={team.id}>
                      {team.compname} - {team.name}
                    </option>
                  ))}
                </select>
                </div>
              </div>

              <div>
                <div className="inline-flex relative">
                <span class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-200 border-e-0 rounded-s-md">
                  Filtrer par dates
                </span>
                <input className="bg-white rounded-none rounded-e-lg border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-200 p-2.5 outline-none" value={`${format(range.startDate, 'MMM dd, yyyy')} - ${format(range.endDate, 'MMM dd, yyyy')}`} readOnly onClick={() => setShowPicker(!showPicker)} />
                </div>
                {showPicker && (
                  <div className="absolute right-0" ref={pickerRef}>
                    <DateRange
                      editableDateInputs={true}
                      onChange={(item) => setRange(item.selection)}
                      moveRangeOnFirstSelection={false}
                      ranges={[range]}
                    />
                  </div>
                )}
              </div>

            </div>

            </div>

            <div className={`grid ${team !== '' ? 'grid-cols-2 lg:grid-cols-7' : 'grid-cols-2 lg:grid-cols-5'} grid gap-5 my-3`}>
              <StatBox value={stats.totallinks} label="Total des liens" />
              {
                team !== '' &&
                <>
                <StatBox value={stats.teamhomelinks} label="Total Home Links" />
                <StatBox value={stats.teamawaylinks} label="Total Away" />
                </>
              }
              <StatBox value={stats.totallivelinks} label="Total Direct" />
              <StatBox value={stats.totalhighlightlinks} label="Total Highlights" />
              <StatBox value={stats.totalgooglelinks} label="Total Google" />
              <StatBox value={stats.totalmatchdays} label="Total Journée" />
            </div>

            {domainstats.length > 0 && (
              <>
                <div>
                  <DataTable data={domainstats} columns={domaincolumns} />
                </div>
                <div className="h-96">
                  <Bar data={domaindataChart} options={domaindataChartOptions} />
                </div>
              </>
            )}
            </section>
        </div>    
        </>
      )}
    </AppLayout>
  );
};

export default Overview;
