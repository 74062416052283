import "./App.css";
import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import ForgotPassword from "./pages/ForgotPassword";
import Dashboard from "./pages/Dashboard";
import AddMember from "./pages/AddMember";
import AllMembers from "./pages/AllMembers";
import MyProfile from "./pages/MyProfile";
import PrivateRoute from "./components/PrivateRoute";
import ChangePassword from "./pages/ChangePassword";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import AllTeams from "./pages/AllTeams";
import AllMatches from "./pages/AllMatches";
import AddMatch from "./pages/AddMatch";
import MatchDetails from "./pages/MatchDetails";
import EditLinkCategories from "./pages/EditLinkCategories";
import MemberMatches from "./pages/Matches";
import MemberMatchDetails from "./pages/MemberMatchDetails";
import Overview from "./pages/Overview";
import TeamDetails from "./pages/TeamDetails";
import Team from "./pages/Team";
import EditWebsitesLinks from "./pages/EditWebsitesLinks";
import MemberOverview from "./pages/MemberOverview";
import Report from "./pages/Report";
import EditMatchdayData from "./pages/EditMatchdayData";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route path="/dashboard" element={ <PrivateRoute> <Dashboard /> </PrivateRoute> } />
        <Route path="/overview" element={ <PrivateRoute> <Overview /> </PrivateRoute> } />
        <Route path="/report" element={ <PrivateRoute> <Report /> </PrivateRoute> } />
        <Route path="/profile" element={ <PrivateRoute> <MyProfile /> </PrivateRoute> } />
        <Route path="/change-password" element={ <PrivateRoute> <ChangePassword /> </PrivateRoute> } />

        <Route path="/add-member" element={ <PrivateRoute allowedto={["Admin"]}> <AddMember /> </PrivateRoute> } />
        <Route path="/edit-member/:userId" element={ <PrivateRoute allowedto={["Admin"]}> <AddMember /> </PrivateRoute> } />
        <Route path="/all-members" element={ <PrivateRoute allowedto={["Admin"]}><AllMembers /> </PrivateRoute> } />

        <Route path="/teams" element={ <PrivateRoute allowedto={["Admin"]}><AllTeams /> </PrivateRoute> } />
        <Route path="/teams/:teamname/:teamId" element={ <PrivateRoute allowedto={["Admin"]}><TeamDetails /> </PrivateRoute> } />

        <Route path="/add-match" element={ <PrivateRoute allowedto={["Admin"]}><AddMatch /> </PrivateRoute> } />
        <Route path="/edit-match/:matchId" element={ <PrivateRoute allowedto={["Admin"]}><AddMatch /> </PrivateRoute> } />
        <Route path="/all-matches" element={ <PrivateRoute allowedto={["Admin"]}><AllMatches /> </PrivateRoute> } />
        <Route path="/match-details/:matchId" element={ <PrivateRoute allowedto={["Admin"]}><MatchDetails /> </PrivateRoute> } />

        <Route path="/edit-link-categories" element={ <PrivateRoute allowedto={["Admin"]}><EditLinkCategories /> </PrivateRoute> } />
        <Route path="/edit-link-categories/:catId" element={ <PrivateRoute allowedto={["Admin"]}><EditLinkCategories /> </PrivateRoute> } />

        <Route path="/edit-websites-links" element={ <PrivateRoute allowedto={["Admin"]}><EditWebsitesLinks /> </PrivateRoute> } />
        <Route path="/edit-websites-links/:webId" element={ <PrivateRoute allowedto={["Admin"]}><EditWebsitesLinks /> </PrivateRoute> } />

        <Route path="/edit-matchday-data" element={ <PrivateRoute allowedto={["Admin"]}><EditMatchdayData /> </PrivateRoute> } />
        <Route path="/edit-matchday-data/:matchDay" element={ <PrivateRoute allowedto={["Admin"]}><EditMatchdayData /> </PrivateRoute> } />

        <Route path="/matches" element={ <PrivateRoute allowedto={["Member"]}> <MemberMatches /> </PrivateRoute> } />
        <Route path="/matches/:competition" element={ <PrivateRoute allowedto={["Member"]}> <MemberMatches /> </PrivateRoute> } />
        <Route path="/matches/:competition/:matchId" element={ <PrivateRoute allowedto={["Member"]}> <MemberMatchDetails /> </PrivateRoute> } />

        <Route path="/team/:teamname/:teamId" element={ <PrivateRoute allowedto={["Member"]}><Team /> </PrivateRoute> } />

      </Routes>
    </Router>
  );
}

export default App;
