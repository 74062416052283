import React, { useContext, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import AuthLayout from "../layouts/AuthLayout";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";

const Home = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ifredirect = searchParams.get("redirect") !== null ? searchParams.get("redirect") : "";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { login, isLoading, autherror } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading === false) {
      try {
        if (email !== "" && password !== "") {
          setError("");
          const response = await login(email, password);
          if (response === "success") {
            if (ifredirect !== "") {
              navigate(ifredirect);
            } else {
              navigate("/dashboard");
            }
          } else {
            setError(response);
          }
        } else {
          setError("All fields are required");
          return;
        }
      } catch (error) {
        setError("Login failed: " + error);
      }
    }
  };

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit} className="login-form">
        <h3 className="heading">Bienvenue</h3>
        <h6>Merci de vous connecter</h6>
        <div>
          <div className="form-group email">
            <label htmlFor="email">Adresse e-mail</label>
            <div className="input-icon">
              <img src="/images/icons/email.png" />
            </div>
            <input id="email" name="email" required type="email" className="form-control" placeholder="hello@protectartists.com" onChange={(e) => setEmail(e.target.value)} value={email} />
          </div>
          <div className="form-group password">
            <label htmlFor="password">Mot de passe</label>
            <div className="input-icon">
              <img src="/images/icons/password.png" />
            </div>
            <input id="password" name="password" required type="password" className="form-control" placeholder="********" onChange={(e) => setPassword(e.target.value)} value={password} />
          </div>
          <p className="text-right mt-3 mb-2">
            <Link className="font-90 font-weight-600 text-main" to="/forgot-password">
              Mot de passe oublié ?
            </Link>
          </p>
          {error !== "" ? <div className="text-center text-red-500 font-semibold text-md mb-3">{error}</div> : <div className="py-4"></div>}
          <button type="submit" name="submit" className="w-full btn btn-main-alt" disabled={isLoading}>
            {isLoading === false ? "Se Connecter" : <Bounce animating={isLoading} />}
          </button>
        </div>
      </form>
    </AuthLayout>
  );
};

export default Home;
