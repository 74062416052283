import React, { useContext, useRef, useEffect, useState, useMemo } from "react";
import AppLayout from "../layouts/AppLayout";
import DataTable from "../components/DataTable";
import { AppContext } from "../contexts/AppContext";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { DateRange } from 'react-date-range';
import {subYears, format} from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { IoIosArrowForward } from "react-icons/io";
import { FaXTwitter, FaTiktok, FaTelegram } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";

const Report = () => {
  const { getoverview, getoverviewstats, getreportmatchdays, getoverviewdaymatches, getoverviewdaywebsites, getoverviewmatchdaydata, getoverviewdomain, getallteams, getteams, isLoading } = useContext(AppContext);
  const [stats, setStats] = useState([]);
  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState("");
  const [domainstats, setdomainStats] = useState([]);
  const [slctdCompetition, setslctdCompetition] = useState(1);
  const [totaldataChart, settotaldataChart] = useState();
  const [totaldataChartOptions, settotaldataChartOptions] = useState();
  const [domaindataChart, setdomaindataChart] = useState();
  const [domaindataChartOptions, setdomaindataChartOptions] = useState();
  const [matchdays, setMatchdays] = useState([]);
  const [matchday, setMatchday] = useState();
  const [matches, setMatches] = useState([]);
  const [websites, setWebsites] = useState([]);
  const [matchdayData, setMatchdayData] = useState([]);
  const [reportLoading, setreportLoading] = useState(false);
  const [websitelivetotal, setwebsitelivetotal] = useState(0);
  const [websitehighlighttotal, setwebsitehighlighttotal] = useState(0);

  const fetchMatchDays = async () => {
    const response = await getreportmatchdays(slctdCompetition);
    setMatchdays(response.matchdays);
  };

  const fetchMatchDaysMatches = async () => {
    setreportLoading(true);
    const response = await getoverviewdaymatches(matchday, slctdCompetition);
    setreportLoading(false);
    setMatches(response);
  };

  const fetchMatchDayWebsites = async () => {
    setreportLoading(true);
    const response = await getoverviewdaywebsites(matchday, slctdCompetition);
    setreportLoading(false);
    setWebsites(response);
  };

  const fetchMatchDayData = async () => {
    setreportLoading(true);
    const response = await getoverviewmatchdaydata(matchday, slctdCompetition);
    setreportLoading(false);
    setMatchdayData(response[0]);
  };

  useEffect(() => {
    fetchMatchDayData();
    fetchMatchDaysMatches();
    fetchMatchDayWebsites();
  }, [matchday]);

  useEffect(() => {
      fetchMatchDays();
  }, [slctdCompetition]);

  const competitions = [1, 2];

  return (
    <AppLayout pageName={"Rapports Journées"}>
      {isLoading ? (
        <div className="flex justify-center w-full py-12">
          <Spinner color="#CCCCCC" size={24} />
        </div>
      ) : (
        <>
        <div className="space-y-5">
            <section>
            {/* <h2 className="text-xl mb-3 font-semibold text-neutral-900 tracking-normal">Domain Based Links</h2> */}

            <div className="flex flex-wrap flex-col lg:flex-row justify-between">

            <div class="inline-flex rounded-md mb-3">
            { competitions.map((competition, index) => {
              return (
                <button onClick={() => setslctdCompetition(competition)} aria-current="page" class={`px-4 py-2 text-sm font-medium tracking-wider ${competition === slctdCompetition ? 'text-white bg-main' : 'text-neutral-800 bg-white border border-gray-200 hover:bg-gray-100'}`}>
                    Ligue {competition}
                </button>
              )
            }) }
            </div>

            </div>

            
            <div className="space-y-3">
              {matchdays.map((day, index) => {
                return (
                  <section className="!p-0" key={index}>
                    <div className={`py-5 px-5 rounded-lg bg-white hover:bg-neutral-50 transition-colors cursor-pointer flex flex-row justify-between space-x-2 items-center ${matchday === day.matchday && 'border-b'}`} onClick={() => setMatchday((matchday === day.matchday) ? "" : day.matchday)}>
                      <h5 className="text-base leading-none font-medium tracking-wide">Journée {day.matchday}</h5>
                      <span className={`${matchday === day.matchday && 'rotate-90'}`}><IoIosArrowForward /></span>
                    </div>
                    {matchday === day.matchday && 
                    <>
                    {reportLoading ? (
                      <div className="flex justify-center w-full py-12">
                        <Spinner color="#CCCCCC" size={24} />
                      </div>
                    ) : (
                    <div className="space-y-10 px-5 py-7">
                    <div>
                        <div>
                            <div className="bg-white border rounded-lg shadow-lg p-6 pb-8">
                                <div className="text-main text-3xl font-extrabold uppercase">PIRATAGE Direct</div>
                            </div>
                            <div className="rounded-xl border shadow mx-5 -mt-5 bg-main py-4">
                                <table class="w-full text-sm text-center text-gray-800">
                                    <thead class="text-xs text-neutral-800 uppercase bg-main-alt">
                                        <tr>
                                            <th colSpan={3} scope="col" class="px-6 pb-4 text-xl text-white bg-main capitalize">
                                                Tableau récapitulatif pour les liens live
                                            </th>
                                        </tr>
                                        <tr>
                                            <th scope="col" class="px-6 py-4 text-left">
                                                Plateformes
                                            </th>
                                            <th scope="col" class="px-6 py-4">
                                                Nombre de liens live
                                            </th>
                                            <th scope="col" class="px-6 py-4">
                                                Nombre de Spectateurs
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                    websites.slice().sort((a, b) => b.livelinks - a.livelinks).map((website, index) => (
                                        <tr class="bg-white border-b-4 border-blue-100">
                                            <th scope="row" class="px-6 py-4 font-medium border-r-4 border-main whitespace-nowrap text-left">
                                                <div className="flex space-x-2 items-center">
                                                    <img width="16" height="16" src={`/images/icons/${website.icon}.png`} />
                                                    <h6 className="text-neutral-900 text-lg">{website.name}</h6>
                                                </div>
                                            </th>
                                            <td class="px-6 py-4 text-lg border-r-4 border-main">
                                                {website.livelinks}
                                            </td>
                                            <td class="px-6 py-4 text-lg">
                                                {website.totalspectators == 0 ? '-' : website.totalspectators}
                                            </td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="bg-white border rounded-lg shadow-lg p-6 pb-8">
                                <div className="text-main text-3xl font-extrabold uppercase">PIRATAGE HIGHLIGHTS</div>
                            </div>
                            <div className="rounded-xl border shadow mx-5 -mt-5 bg-main py-4">
                                <table class="w-full text-sm text-center text-gray-800">
                                    <thead class="text-xs text-neutral-800 uppercase bg-main-alt">
                                        <tr>
                                            <th colSpan={2} scope="col" class="px-6 pb-4 text-xl text-white bg-main capitalize">
                                                Tableau récapitulatif pour les liens highlights
                                            </th>
                                        </tr>
                                        <tr>
                                            <th scope="col" class="px-6 py-4 text-left">
                                                Plateformes
                                            </th>
                                            <th scope="col" class="px-6 py-4">
                                                Nombre de liens highlights notifiés
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                    websites.slice().sort((a, b) => b.highlightlinks - a.highlightlinks).map((website, index) => (
                                        <tr class="bg-white border-b-4 border-blue-100">
                                            <th scope="row" class="px-6 py-4 font-medium border-r-4 border-main whitespace-nowrap text-left">
                                                <div className="flex space-x-2 items-center">
                                                    <img width="16" height="16" src={`/images/icons/${website.icon}.png`} />
                                                    <h6 className="text-neutral-900 text-lg">{website.name}</h6>
                                                </div>
                                            </th>
                                            <td class="px-6 py-4 text-lg">
                                                {website.highlightlinks}
                                            </td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="bg-white border rounded-lg shadow-lg p-6 pb-8">
                                <div className="text-xs leading-none text-main-alt font-medium">TOTAL</div>
                                <div className="text-main text-3xl font-extrabold uppercase">TOP ÉVÉNEMENTS</div>
                            </div>
                            <div className="rounded-xl border shadow mx-5 -mt-5 bg-main py-4">
                                <table class="w-full text-sm text-center text-gray-800">
                                    <thead class="text-xs text-white uppercase bg-main">
                                        <tr>
                                            <th scope="col" class="px-6 pb-4 text-left">
                                                Event
                                            </th>
                                            <th scope="col" class="px-6 pb-4">
                                                Date
                                            </th>
                                            <th scope="col" class="px-6 pb-4">
                                                Total des liens
                                            </th>
                                        </tr>
                                        <tr>
                                            <th colSpan={3} scope="col" class="px-6 py-3 text-left bg-main-alt capitalize text-neutral-800">
                                                Total (Direct, Highlights, Google)
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                    matches.slice().sort((a, b) => b.totallinks - a.totallinks).map((match, index) => (
                                        <tr class="bg-white border-b-4 border-blue-100">
                                            <th scope="row" class="px-6 py-5 font-medium border-r-4 border-main whitespace-nowrap text-left">
                                                {match.team1name} - {match.team2name}
                                            </th>
                                            <td class="px-6 py-5 border-r-4 border-main">
                                                {format(match.date, "dd/MM/yyyy")}
                                            </td>
                                            <td class="px-6 py-5">
                                                {match.totallinks}
                                            </td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="bg-white border rounded-lg shadow-lg p-6 pb-8">
                                <div className="text-xs leading-none text-main-alt font-medium">DIRECT</div>
                                <div className="text-main text-3xl font-extrabold uppercase">TOP ÉVÉNEMENTS</div>
                            </div>
                            <div className="rounded-xl border shadow mx-5 -mt-5 bg-main py-4">
                                <table class="w-full text-sm text-center text-gray-800">
                                    <thead class="text-xs text-white uppercase bg-main">
                                        <tr>
                                            <th scope="col" class="px-6 pb-4 text-left">
                                                Event
                                            </th>
                                            <th scope="col" class="px-6 pb-4">
                                                Date
                                            </th>
                                            <th scope="col" class="px-6 pb-4">
                                                Total des liens
                                            </th>
                                        </tr>
                                        <tr>
                                            <th colSpan={3} scope="col" class="px-6 py-3 text-left bg-main-alt capitalize text-neutral-800">
                                                Direct
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                    matches.slice().sort((a, b) => b.livelinks - a.livelinks).map((match, index) => (
                                        <tr class="bg-white border-b-4 border-blue-100">
                                            <th scope="row" class="px-6 py-5 font-medium border-r-4 border-main whitespace-nowrap text-left">
                                                {match.team1name} - {match.team2name}
                                            </th>
                                            <td class="px-6 py-5 border-r-4 border-main">
                                                {format(match.date, "dd/MM/yyyy")}
                                            </td>
                                            <td class="px-6 py-5">
                                                {match.livelinks}
                                            </td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="bg-white border rounded-lg shadow-lg p-6 pb-8">
                                <div className="text-xs leading-none text-main-alt font-medium">SPECTATORS</div>
                                <div className="text-main text-3xl font-extrabold uppercase">TOP ÉVÉNEMENTS</div>
                            </div>
                            <div className="rounded-xl border shadow mx-5 -mt-5 bg-main py-4">
                                <table class="w-full text-sm text-center text-gray-800">
                                    <thead class="text-xs text-white uppercase bg-main">
                                        <tr>
                                            <th scope="col" class="px-6 pb-4 text-left">
                                                Event
                                            </th>
                                            <th scope="col" class="px-6 pb-4">
                                                Date
                                            </th>
                                            <th scope="col" class="px-6 pb-4">
                                                NOMBRE DE SPECTATEUR
                                            </th>
                                        </tr>
                                        <tr>
                                            <th colSpan={3} scope="col" class="px-6 py-3 text-left bg-main-alt capitalize text-neutral-800">
                                                Spectators
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                    matches.slice().sort((a, b) => b.totalspectators - a.totalspectators).map((match, index) => (
                                        <tr class="bg-white border-b-4 border-blue-100">
                                            <th scope="row" class="px-6 py-5 font-medium border-r-4 border-main whitespace-nowrap text-left">
                                                {match.team1name} - {match.team2name}
                                            </th>
                                            <td class="px-6 py-5 border-r-4 border-main">
                                                {format(match.date, "dd/MM/yyyy")}
                                            </td>
                                            <td class="px-6 py-5">
                                                {match.totalspectators}
                                            </td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="bg-white border rounded-lg shadow-lg p-6 pb-8">
                                <div className="text-xs leading-none text-main-alt font-medium">HIGHLIGHTS</div>
                                <div className="text-main text-3xl font-extrabold uppercase">TOP ÉVÉNEMENTS</div>
                            </div>
                            <div className="rounded-xl border shadow mx-5 -mt-5 bg-main py-4">
                                <table class="w-full text-sm text-center text-gray-800">
                                    <thead class="text-xs text-white uppercase bg-main">
                                        <tr>
                                            <th scope="col" class="px-6 pb-4 text-left">
                                                Event
                                            </th>
                                            <th scope="col" class="px-6 pb-4">
                                                Date
                                            </th>
                                            <th scope="col" class="px-6 pb-4">
                                                Total des liens
                                            </th>
                                        </tr>
                                        <tr>
                                            <th colSpan={3} scope="col" class="px-6 py-3 text-left bg-main-alt capitalize text-neutral-800">
                                                Highlights
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                    matches.slice().sort((a, b) => b.highlightlinks - a.highlightlinks).map((match, index) => (
                                        <tr class="bg-white border-b-4 border-blue-100">
                                            <th scope="row" class="px-6 py-5 font-medium border-r-4 border-main whitespace-nowrap text-left">
                                                {match.team1name} - {match.team2name}
                                            </th>
                                            <td class="px-6 py-5 border-r-4 border-main">
                                                {format(match.date, "dd/MM/yyyy")}
                                            </td>
                                            <td class="px-6 py-5">
                                                {match.highlightlinks}
                                            </td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="bg-white border rounded-lg shadow-lg p-6 pb-8">
                                <div className="text-main text-3xl font-extrabold uppercase">COMPTES NO TIFIÉS ET SUSPENDUS</div>
                                <div className="flex flex-wrap space-x-5 mt-3 ml-1 text-black text-lg">
                                  <div className="flex space-x-2 items-center"><FaXTwitter/> <span>X / Twitter</span></div>
                                  <div className="flex space-x-2 items-center"><FaTiktok/> <span>Tiktok</span></div>
                                  <div className="flex space-x-2 items-center"><FaTelegramPlane/> <span>Telegram</span></div>
                                </div>
                            </div>
                            <div className="rounded-xl border shadow mx-5 -mt-5 bg-white">
                              <div className="rounded-t-xl bg-main p-4">
                                <h4 className="text-white font-semibold text-2xl text-center">Comptes Suspendus</h4>
                              </div>
                              <div className="p-10x acc-table">
                                <table class="w-full text-sm text-center text-gray-800">
                                  <tr class="bg-white border-b-4 border-blue-100">
                                      <th scope="row" class="px-6 py-4 font-medium border-r-4 border-main whitespace-nowrap text-left w-1/2">
                                        <div className="flex space-x-2 items-center">
                                            <img width="16" height="16" src={`/images/icons/x.png`} />
                                            <h6 className="text-neutral-900 text-lg">X / Twitter</h6>
                                        </div>
                                      </th>
                                      <td class="px-6 py-5 w-1/2 text-lg">{matchdayData && matchdayData.accsuspendedtwitter}</td>
                                  </tr>
                                  <tr class="bg-white border-b-4 border-blue-100">
                                      <th scope="row" class="px-6 py-4 font-medium border-r-4 border-main whitespace-nowrap text-left">
                                        <div className="flex space-x-2 items-center">
                                            <img width="16" height="16" src={`/images/icons/tiktok.png`} />
                                            <h6 className="text-neutral-900 text-lg">Tiktok</h6>
                                        </div>
                                      </th>
                                      <td class="px-6 py-5 text-lg">{matchdayData && matchdayData.accsuspendedtiktok}</td>
                                  </tr>
                                  <tr class="bg-white">
                                      <th scope="row" class="px-6 py-4 font-medium border-r-4 border-main whitespace-nowrap text-left">
                                        <div className="flex space-x-2 items-center">
                                            <img width="16" height="16" src={`/images/icons/telegram.png`} />
                                            <h6 className="text-neutral-900 text-lg">Telegram</h6>
                                        </div>
                                      </th>
                                      <td class="px-6 py-5 text-lg">{matchdayData && matchdayData.accsuspendedtelegram}</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <div className="rounded-xl border shadow mx-5 mt-3 bg-white">
                              <div className="rounded-t-xl bg-main-alt p-4">
                                <h4 className="text-black font-semibold text-2xl text-center">Comptes Notifies</h4>
                              </div>
                              <div className="p-10x">
                                <table class="w-full text-sm text-center text-gray-800">
                                  <tr class="bg-white border-b-4 border-blue-100">
                                      <th scope="row" class="px-6 py-4 font-medium border-r-4 border-main whitespace-nowrap text-left w-1/2">
                                        <div className="flex space-x-2 items-center">
                                            <img width="16" height="16" src={`/images/icons/x.png`} />
                                            <h6 className="text-neutral-900 text-lg">X / Twitter</h6>
                                        </div>
                                      </th>
                                      <td class="px-6 py-5 text-lg w-1/2">{matchdayData && matchdayData.accnotifiedtwitter}</td>
                                  </tr>
                                  <tr class="bg-white border-b-4 border-blue-100">
                                      <th scope="row" class="px-6 py-4 font-medium border-r-4 border-main whitespace-nowrap text-left">
                                        <div className="flex space-x-2 items-center">
                                            <img width="16" height="16" src={`/images/icons/tiktok.png`} />
                                            <h6 className="text-neutral-900 text-lg">Tiktok</h6>
                                        </div>
                                      </th>
                                      <td class="px-6 py-5 text-lg">{matchdayData && matchdayData.accnotifiedtiktok}</td>
                                  </tr>
                                  <tr class="bg-white">
                                      <th scope="row" class="px-6 py-4 font-medium border-r-4 border-main whitespace-nowrap text-left">
                                        <div className="flex space-x-2 items-center">
                                            <img width="16" height="16" src={`/images/icons/telegram.png`} />
                                            <h6 className="text-neutral-900 text-lg">Telegram</h6>
                                        </div>
                                      </th>
                                      <td class="px-6 py-5 text-lg">{matchdayData && matchdayData.accnotifiedtelegram}</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <div className="rounded-xl border shadow mx-5 mt-3 bg-white">
                              <div className="rounded-t-xl bg-main-alt p-4">
                                <h4 className="text-black font-semibold text-2xl text-center">Membres Impactés</h4>
                              </div>
                              <div className="p-10x">
                                <table class="w-full text-sm text-center text-gray-800">
                                  <tr class="bg-white">
                                      <th scope="row" class="px-6 py-4 font-medium border-r-4 border-main whitespace-nowrap text-left w-1/2">
                                        <div className="flex space-x-2 items-center">
                                            <img width="16" height="16" src={`/images/icons/telegram.png`} />
                                            <h6 className="text-neutral-900 text-lg">Telegram</h6>
                                        </div>
                                      </th>
                                      <td class="px-6 py-5 w-1/2 text-lg">{matchdayData && matchdayData.membersinspactedtelegram}</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    )}
                    </>
                    }
                  </section>
                );
              })}
            </div>

            </section>
        </div>    
        </>
      )}
    </AppLayout>
  );
};

export default Report;
