import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children, allowedto }) => {
  const isAuthenticated = localStorage.getItem("userToken");
  let hasRequiredRole;
  if (isAuthenticated) {
    hasRequiredRole = allowedto ? allowedto.includes(JSON.parse(isAuthenticated).type) : true;
  }
  return isAuthenticated ? hasRequiredRole ? children : <Navigate to="/dashboard" replace /> : <Navigate to="/" replace />;
};

export default PrivateRoute;
