import AppLayout from "../layouts/AppLayout";
import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { format } from "date-fns"; import { fr } from 'date-fns/locale';
import "../styles/players.css";

const Team = () => {
  const { teamId } = useParams();

  const navigate = useNavigate();

  const [team, setTeam] = useState("");
  const [tab, setTab] = useState("Matches");
  const [matches, setMatches] = useState([]);
  const [players, setPlayers] = useState([]);
  const [mLoading, setmLoading] = useState(false);
  const [sLoading, setsLoading] = useState(false);

  const { getteam, getteammatches, getteamplayers, isLoading } = useContext(AppContext);

  const positionarr = ["Attacker","Midfielder","Defender","Goalkeeper"];
  const colorsarr = ["bg-red-500","bg-orange-400","bg-green-400","bg-gray-400"];

  const fetchTeam = async () => {
    const response = await getteam(teamId);
    if (response) {
      if (response[0] !== "") {
        setTeam(response[0]);
      } else {
        navigate("/teams");
      }
    } else {
      navigate("/teams");
    }
  };

  useEffect(() => {
    if (teamId) {
      fetchTeam();
    }
  }, [teamId]);

  const fetchTeamMatches = async () => {
    setmLoading(true);
    const response = await getteammatches(teamId);
    setMatches(response);
    setmLoading(false);
  };

  const fetchTeamPlayers = async () => {
    setsLoading(true);
    const response = await getteamplayers(teamId);
    setPlayers(response);
    setsLoading(false);
  };

  useEffect(() => {
    if(tab === 'Matches'){
        fetchTeamMatches();
    }
    else if(tab === 'Squad'){
        fetchTeamPlayers();
    }
  }, [tab]);

  return (
    <AppLayout pageName="Team Details">
      {team !== "" ? (
        <>
          <section className="space-y-4 players">
            <div className="p-2">
              <div className="flex flex-row space-x-6 items-center">
                <img width="100" height="100" src={`/images/teams/${team.name.toLowerCase().replaceAll(" ", "")}.png`} /> <div><div className="text-sm text-neutral-500 tracking-wide font-medium mb-2">{team.compname}</div><div className="text-5xl font-black uppercase tracking-wider heading text-black">{team.name}</div> </div>
              </div>
            </div>
            <div class="text-sm font-medium text-center text-gray-500 border-b border-gray-200">
              <ul class="flex flex-wrap -mb-px font-medium text-base tracking-wide">
                <li class="me-2">
                    <button onClick={() => setTab('Matches')} className={`inline-block px-5 py-3 border-b-2 ${tab === 'Matches' ? 'text-main-alt border-main-alt' : 'border-transparent hover:text-gray-600 hover:border-gray-300'} rounded-t-lg active`}>Matches</button>
                </li>
                <li class="me-2">
                    <button onClick={() => setTab('Squad')} className={`inline-block px-5 py-3 border-b-2 ${tab === 'Squad' ? 'text-main-alt border-main-alt' : 'border-transparent hover:text-gray-600 hover:border-gray-300'} rounded-t-lg active`}>Squad</button>
                </li>
              </ul>
            </div>

            <div>
            { tab === 'Matches' && 
            <>
              { mLoading ? 
              <div className="flex justify-center w-full py-12">
                  <Spinner color="#CCCCCC" size={24} />
              </div> 
              : 
              matches.length > 0 ?
              <>
              <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-5 py-5">
              {matches.map((match, index) => {
                  return (
                  <div key={index}>
                  <Link to={`/matches/${team.compname}/${match.matchid}`}>
                      <div className={`py-4 px-5 rounded-lg bg-neutral-50 hover:bg-neutral-100 border transition-colors cursor-pointer`}>
                      <div className="flex flex-row justify-between headingx items-center divide-x">
                          <div className="w-8/12 space-y-4">
                            <div className="flex flex-row justify-between items-center">
                              <div className="flex flex-row space-x-4 text-lg font-semibold text-black items-center text-center">
                                <img alt={match.team1name} className="rounded-full object-contain border" width="40" height="40" src={`/images/teams/${match.team1name.toLowerCase().replaceAll(" ", "")}.png`} /> <div>{match.team1name}</div>
                              </div>
                              {(match.team1score != null || match.team2score != null) && <>
                                <div className="pr-5 font-medium text-main">{match.team1score}</div>
                              </> }
                            </div>
                            <div className="flex flex-row justify-between items-center">
                              <div className="flex flex-row space-x-4 text-lg font-semibold text-black items-center text-center">
                              <img alt={match.team2name} className="rounded-full object-contain border" width="40" height="40" src={`/images/teams/${match.team2name.toLowerCase().replaceAll(" ", "")}.png`} /> <div>{match.team2name}</div>
                              </div>
                              {(match.team1score != null || match.team2score != null) && <>
                                <div className="pr-5 font-medium text-main">{match.team2score}</div>
                              </> }
                            </div>
                          </div>
                          <div className="w-4/12">
                          <div className="flex flex-col items-center relative space-y-2">
                              {match.matchday !== "" && <div className="absolutex -bottom-8 border text-[10px] tracking-wide text-neutral-400 font-medium bg-white rounded-full p-1 px-3">Matchday {match.matchday}</div>}
                              <div className="flex flex-col text-center text-xs justify-center font-medium text-neutral-600 tracking-wide space-y-2">
                                <div>{format(match.date, "do MMM yyyy")}</div>
                                <div>{match.time}</div>
                              </div>
                          </div>
                          </div>
                      </div>
                      </div>
                  </Link>
                  </div>
                  );
              })}
              </div>
              </>
              :
              <div className="text-center w-full py-12 text-neutral-400">No Matches Found</div>
              }
            </>
            }    
            { tab === 'Squad' && 
            <>
            { sLoading ? 
              <div className="flex justify-center w-full py-12">
                  <Spinner color="#CCCCCC" size={24} />
              </div> 
              : 
            <>
              {
                players.length > 0 ?
                <>
                <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 pt-5">
                {players.map((player, index) => {
                    return (
                    <div key={index}>
                      <div className={`p-3 rounded-lg bg-neutral-50 hover:bg-neutral-100 border transition-colors relative cursor-pointer`}>
                        <div className="flex flex-row space-x-4 items-center">
                          <div className={`w-12 h-12 rounded-md ${colorsarr[positionarr.indexOf(player.position)]}`}></div>
                          <div>
                            <div className="text-neutral-500 font-medium tracking-wide uppercase text-xs">{player.position}</div>
                            <div className="heading text-xl tracking-wide font-semibold">{player.name}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    );
                })}
                </div>
                </>
                :
                <div className="text-center w-full py-12 text-neutral-400">No Players Found</div>
              }
            </>
            }
            </>
            }
            </div>

          </section>
        </>
      ) : (
        <div className="flex justify-center w-full py-12">
          <Spinner color="#CCCCCC" size={24} />
        </div>
      )}
    </AppLayout>
  );
};

export default Team;
